




















import ConfettiInfoIcon from '@/assets/ConfettiInfoIcon.svg?inline'
import { ConfettiPrimaryButton } from '@/components/ConfettiPrimaryButton.vue'
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {
    ConfettiPrimaryButton,
    ConfettiInfoIcon,
  },
  head: {
    bodyAttrs: {
      'data-mobile-cover': 'true',
    },
  },
})
export class ConfettiMobileCover extends Vue {}

export default ConfettiMobileCover
