











import { Component, Vue } from 'nuxt-property-decorator'

@Component
export class ConfettiLoader extends Vue {}

export default ConfettiLoader
