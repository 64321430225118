import { Plugin } from '@nuxt/types'
import * as Sentry from '@sentry/browser'
import { Route } from 'vue-router'

const ChunkLoadErrorPlugin: Plugin = ({ app }) => {
  // @ts-ignore
  window.onNuxtReady(() => {
    let fullPath: string

    Sentry.addGlobalEventProcessor((event) => {
      if (event.exception?.values) {
        const [error] = event.exception.values
        if (error?.type === 'ChunkLoadError') {
          window.location.assign(fullPath)
          return null
        }
      }

      return event
    })

    // @ts-ignore
    const { prototype } = app.router.history.constructor
    const originalFn = prototype.confirmTransition

    prototype.confirmTransition = function (
      route: Route,
      onComplete: Function,
      onAbort: Function,
    ) {
      fullPath = route.fullPath
      originalFn.call(this, route, onComplete, onAbort)
    }
  })
}

export default ChunkLoadErrorPlugin
