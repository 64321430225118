








import * as Sentry from '@sentry/browser'
import { defineComponent } from '@nuxtjs/composition-api'
import { AxiosError } from 'axios'
import { ConfettiMobileCover } from '@/components/ConfettiMobileCover.vue'

export default defineComponent({
  components: {
    ConfettiMobileCover,
  },
  errorCaptured(err) {
    if (process.env.NODE_ENV !== 'production') return true

    const { response, request } = err as AxiosError

    if (!window.navigator.onLine || request?.status === 0) {
      alert(
        'You are experiencing connectivity issues - Please check your internet connection and try again',
      )
      return false
    }

    if (response) Sentry.setExtra('response', response)
    Sentry.captureException(err)
    alert(
      'Oops! Something went wrong.\nOur R&D team is already informed and looking into this issue.\n\nPress OK to reload',
    )
    window.location.reload()
    return false
  },
})
