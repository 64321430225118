


















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RawLocation } from 'vue-router'
import { ConfettiLoader } from '~/components/ConfettiLoader.vue'

@Component({
  components: {
    ConfettiLoader,
  },
})
export class ConfettiPrimaryButton extends Vue {
  @Prop() to?: RawLocation
  @Prop(Boolean) loading?: boolean
}

export default ConfettiPrimaryButton
