import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5aec2878 = () => interopDefault(import('../pages/competition/_id.vue' /* webpackChunkName: "pages/competition/_id" */))
const _13600d86 = () => interopDefault(import('../pages/game/_slug/index.vue' /* webpackChunkName: "pages/game/_slug/index" */))
const _fe9c50ee = () => interopDefault(import('../pages/game/_slug/index/index.vue' /* webpackChunkName: "pages/game/_slug/index/index" */))
const _ad456572 = () => interopDefault(import('../pages/game/_slug/index/chapter/index.vue' /* webpackChunkName: "pages/game/_slug/index/chapter/index" */))
const _f2d0c11c = () => interopDefault(import('../pages/game/_slug/index/chapter/index/_id.vue' /* webpackChunkName: "pages/game/_slug/index/chapter/index/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/competition/:id?",
    component: _5aec2878,
    name: "competition-id"
  }, {
    path: "/game/:slug",
    component: _13600d86,
    children: [{
      path: "",
      component: _fe9c50ee,
      name: "game-slug-index"
    }, {
      path: "chapter",
      component: _ad456572,
      name: "game-slug-index-chapter",
      children: [{
        path: ":id?",
        component: _f2d0c11c,
        name: "game-slug-index-chapter-index-id"
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
